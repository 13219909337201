import React from "react"
import { Link } from "gatsby"
import Layout from "../layout"
import SEO from "../seo"
import Pager from "./post-pager"
import {
  ContentListWrapper,
  ContentWrapper,
  ContentHeader,
  ContentInfo,
  ContentDate,
  ContentCategory,
  ContentTitle,
  ContentBody,
  ContentImage,
  ContentText,
} from "../parts/content"
import Tags from "./tags"
import { css } from "@emotion/core"
import mediaQuery from "styled-media-query"

const mediaMobile = mediaQuery.lessThan("medium")

// import "./layout.css"

const PostList = ({ pageContext }) => {
  const {
    group,
    index,
    first,
    last,
    pageCount,
    pathPrefix,
    additionalContext,
  } = pageContext

  const listConf = {
    top: {
      title: () => {
        return ""
      },
      showCategoryLabel: true,
    },
    category: {
      title: () => {
        return getLabel(additionalContext.categoryList, pathPrefix)
      },
      showCategoryLabel: false,
    },
    tag: {
      title: () => {
        return getLabel(additionalContext.tagList, additionalContext.tag)
      },
      showCategoryLabel: true,
    },
    archives: {
      title: () => {
        return pathPrefix.replace(`archives/`, ``)
      },
      showCategoryLabel: true,
    },
  }
  const listType = additionalContext.listType

  return (
    <Layout>
      <SEO title={listConf[listType].title()}></SEO>
      <ContentListWrapper>
        {group.map(({ node }, index) => (
          <div
            css={css`
              width: 50%;
              ${mediaMobile`
                width: 100%;
              `}
            `}
            key={`post-list-${node.id}`}
          >
            <ContentWrapper
              className={[`col` + ((index % 2) + 1), `list`].join(` `)}
            >
              <Link
                to={node.fields.slug}
                css={css`
                  text-decoration: none;
                  color: hsla(0, 0%, 0%, 0.8);
                  // color: #00adb5;
                  // font-size: 1.5em;
                  // display: table-cell;
                  // vertical-align: middle;
                  // height: 100%;
                  // width: 40px;
                  // text-align: center;
                  // text-shadow: 6px 0px 0px #393e46;
                `}
              >
                <ContentHeader>
                  <ContentInfo>
                    <ContentDate className="font-pixel">
                      {node.frontmatter.date}
                    </ContentDate>
                    {listConf[listType].showCategoryLabel ? (
                      <ContentCategory className="font-pixel">
                        {getLabel(
                          additionalContext.categoryList,
                          node.frontmatter.category
                        )}
                      </ContentCategory>
                    ) : (
                      <></>
                    )}
                  </ContentInfo>
                  <ContentTitle tag="h2">{node.frontmatter.title}</ContentTitle>
                </ContentHeader>
                <ContentBody>
                  <ContentImage
                    src={
                      node.frontmatter.heading_image ||
                      `/assets/thumbnail/${node.frontmatter.category}.png`
                    }
                    alt={node.frontmatter.category}
                  >
                    test
                  </ContentImage>
                  <div
                    css={css`
                      min-width: 200px;
                      width: auto;
                      display: table-cell;
                      vertical-align: middle;
                    `}
                  >
                    <ContentText
                      dangerouslySetInnerHTML={{ __html: node.excerpt }}
                    ></ContentText>
                  </div>
                </ContentBody>
              </Link>
              <Tags
                list={additionalContext.tagList}
                values={node.frontmatter.tags}
              ></Tags>
            </ContentWrapper>
          </div>
        ))}
      </ContentListWrapper>
      <Pager
        pathPrefix={pathPrefix}
        index={index}
        first={first}
        last={last}
        pageCount={pageCount}
      ></Pager>
    </Layout>
  )
}

const getLabel = (options, value) => {
  const category = options.filter(opt => opt.value === value)
  return category.length === 0 ? null : category[0].label
}

export default PostList
