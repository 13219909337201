import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const PagerWrapper = styled("div")`
  font-size: x-small;
  padding-top: 10px;
  text-align: right;
`
const PagerLinkWrapper = styled("div")`
  margin-left: auto;
  padding: 0px;
  height: 35px;
  display: inline-block;
`
const PagerItem = styled("span")`
  color: #eeeeee;
  text-align: center;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: #393e46;
  border-bottom: solid 3px;
  border-bottom-color: #00adb5;
  display: inline-block;
  &.is-disabled {
    display: none;
    // color: #eeeeee;
    // background-color: #ffffff;
  }
`
const PagerLink = styled(Link)`
  text-decoration: none;
`
const PagerCount = styled("span")`
  text-decoration: none;
  color: #eeeeee;
  text-align: center;
  height: 35px;
  width: 70px;
  line-height: 35px;
  display: inline-block;
  // background-color: #00adb5;
  color: #393e46;
`

const generatePageLinks = (
  pathPrefix,
  currentIndex,
  first,
  last,
  pageCount
) => {
  let elements = []
  const previousIndex = currentIndex - 1
  if (!first) {
    const previousUrl =
      previousIndex === 1
        ? `${pathPrefix}/`
        : `${pathPrefix}/` + previousIndex.toString()
    elements.push(
      <PagerLink to={previousUrl} key={previousIndex}>
        <PagerItem>{"<"}</PagerItem>
      </PagerLink>
    )
  } else {
    elements.push(
      <PagerItem key={previousIndex} className="is-disabled">
        {"<"}
      </PagerItem>
    )
  }
  elements.push(
    <PagerCount key={currentIndex}>
      {currentIndex}/{pageCount}
    </PagerCount>
  )
  const nextIndex = currentIndex + 1
  if (!last) {
    const nextUrl = `${pathPrefix}/` + nextIndex.toString()
    elements.push(
      <PagerLink to={nextUrl} key={nextIndex}>
        <PagerItem>{">"}</PagerItem>
      </PagerLink>
    )
  } else {
    elements.push(
      <PagerItem key={nextIndex} className="is-disabled">
        {">"}
      </PagerItem>
    )
  }
  return elements
}

const Pager = ({ pathPrefix, index, first, last, pageCount }) => {
  return (
    <PagerWrapper className="font-pixel">
      <PagerLinkWrapper>
        {generatePageLinks(pathPrefix, index, first, last, pageCount)}
      </PagerLinkWrapper>
    </PagerWrapper>
  )
}
export default Pager
